import { defaultFilter } from '../../../context/Filter.context';
import { TFilters } from '../types/types';
import { defaultSort } from '../../../context/Sort.context';
import getParsedData from './getParsedData';
import { applyRoleBasedFilters } from './roleBasedFilters';

type GetFilterParams = {
  getTabId: (input: string) => string;
  userRole?: string;
  userDefaultFilters?: string;
  userFilter?: object;
};

export const getFiltersForRole =
  (searchQuery: string, role?: string, userDefaultFilters?: string): Partial<TFilters> => {
    const updatedFilters = role && searchQuery === ''
      ? applyRoleBasedFilters(defaultFilter, role, userDefaultFilters)
      : defaultFilter;

    return updatedFilters;
  };

const getFilter = ({
  getTabId,
  userRole = '',
  userDefaultFilters = ''
}: GetFilterParams) => {
  const searchQuery = window.location.search;
  const tab = window.location.pathname.split('/')[2];
  const tabId = getTabId(tab);
  const parsedData = getParsedData(searchQuery, tabId);

  const initialFilters = getFiltersForRole(searchQuery, userRole, userDefaultFilters);

  const modifiedFilter = { ...initialFilters };
  const modifiedSort = { ...defaultSort };

  if (parsedData?.filters) {
    modifiedFilter[tabId] = {
      ...initialFilters[tabId],
      ...parsedData.filters
    };
  }

  if (parsedData?.sort) {
    modifiedSort[tabId] = {
      ...defaultSort[tabId],
      ...parsedData.sort
    };
  }

  return {
    filter: {
      ...modifiedFilter,
      ...(parsedData?.filters ? { [tabId]: { ...modifiedFilter[tabId] } } : {})
    },
    sort: {
      ...modifiedSort,
      ...(parsedData?.sort ? { [tabId]: { ...modifiedSort[tabId] } } : {})
    }
  };
};

export default getFilter;
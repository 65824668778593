import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useUserPermit } from '../../context/User.context';
import { Routes, TAB_NAME } from '../../core/constants';
import {
  AllExcluded,
  AllNewTitle,
  BookstatData,
  Watchlist,
  Competitors,
  PendingExclusion,
  PodiumSales,
  PodiumTitles,
  SendToCRM,
  HotTitles
} from '../Common/tabs';
import '../Common/styles.css';
import ManuallyAdded from '../Common/tabs/ManuallyAdded';
import Tabs from '../../components/Tabs';
import SavedSearchSection from '../SavedSearches/SavedSearch.section';
import { defaultSort, useSort } from '../../context/Sort.context';

const tabs = [
  {
    key: Routes.allNewTitle,
    label: TAB_NAME.allnewtitles
  },
  {
    key: Routes.hotTitles,
    label: TAB_NAME.hotTitles
  },
  {
    key: Routes.watchlist,
    label: TAB_NAME.watchlist
  },
  {
    key: Routes.pendingExclusion,
    label: TAB_NAME.pendingexclusion
  },
  {
    key: Routes.allExcluded,
    label: TAB_NAME.allexcluded
  },
  {
    key: Routes.manuallyAdded,
    label: TAB_NAME.manuallyadded
  },
  {
    key: Routes.competitors,
    label: TAB_NAME.competitors
  },
  {
    key: Routes.podiumTitles,
    label: TAB_NAME.podiumtitles
  },
  {
    key: Routes.sentToCrm,
    label: TAB_NAME.sendtocrm
  },
  {
    key: Routes.bookstatData,
    label: TAB_NAME.bookstatdata
  },
  {
    key: Routes.podiumSales,
    label: TAB_NAME.podiumsales
  },
  {
    key: Routes.savedSearchesBookstat,
    label: TAB_NAME.savedSearches
  }
];

const BooksSection = () => {
  const { view_tables } = useUserPermit();
  const { setSort } = useSort();
  const history = useHistory();

  return view_tables ? (
    <>
      <Tabs
        defaultActiveKey={'all-new-titles'}
        onChange={key => {
          history.push(key);

          if (!window.location.search) {
            setSort(defaultSort);
          }
        }}
        tabs={tabs}
        activeKey={window.location?.pathname}
      />
      <Switch>
        <Route path={Routes.allNewTitle} render={() => <AllNewTitle />} />
        <Route path={Routes.hotTitles} render={() => <HotTitles />} />
        <Route path={Routes.watchlist} render={() => <Watchlist />} />
        <Route path={Routes.pendingExclusion} render={() => <PendingExclusion />} />
        <Route path={Routes.allExcluded} render={() => <AllExcluded />} />
        <Route path={Routes.manuallyAdded} render={() => <ManuallyAdded />} />
        <Route path={Routes.competitors} render={() => <Competitors />} />
        <Route path={Routes.podiumTitles} render={() => <PodiumTitles />} />
        <Route path={Routes.sentToCrm} render={() => <SendToCRM />} />
        <Route path={Routes.bookstatData} render={() => <BookstatData />} />
        <Route path={Routes.podiumSales} render={() => <PodiumSales />} />
        <Route path={Routes.savedSearchesBookstat} render={() => <SavedSearchSection />} />
        <Redirect to='/bookstat/all-new-titles' />
      </Switch>
    </>
  ) : (
    <></>
  );
};

export default BooksSection;
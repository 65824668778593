import { Table as Tb } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  DeleteOutlined,
  StopOutlined,
  EyeOutlined,
  SolutionOutlined,
  FlagOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import {
  ALL_NEW_TITLE,
  PENDING_EXCLUSION,
  PODIUM_TITLES,
  WATCHLIST,
  RETURN,
  ALL_EXCLUDED,
  ALL_EXCLUDED_RR,
  ALL_TITLES_RR,
  COMPETITORS,
  MANUALLY_ADDED,
  POPULAR,
  RISING_STARS,
  SEND_TO_CRM,
  SENT_TO_CRM_RR,
  TRENDING,
  WATCHLIST_RR,
  PENDING_EXCLUSION_RR,
  PODIUM_TITLES_RR,
  HOT_TITLES
} from '../../core/constants';

const MenuItem = styled.div`
  color: ${({ hide }) => hide && '#d6d6d6 !important'};
  cursor: ${({ hide }) => hide && 'default'};
  span {
    color: ${({ hide }) => hide && '#d6d6d6 !important'};
    margin-right: 6px;
  }
  .icons-flag {
    color: orange;
  }
  .icons-eye {
    color: green;
  }
  .icons-pending-exclusion {
    color: rgb(153, 153, 42);
  }
  .icons-trash {
    color: red;
  }
  .icons-podium-title {
    color: #8108b3;
  }
  .icons-rollback {
    color: grey;
  }
`;

export const commonOptions = (selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal) => [
  {
    key: 'blockAll',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <StopOutlined className='icons-trash' />
        Block Selected Author(s)
      </MenuItem>
    ),
    onSelect: () => selectedTitles?.length && setOpenBlockAuthorsModal(true)
  },
  {
    key: 'deleteAll',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <DeleteOutlined className='icons-trash' />
        Delete all
      </MenuItem>
    ),
    onSelect: () => selectedTitles?.length && setOpenDeleteModal(true)
  },
  Tb.SELECTION_INVERT
];

export const toPendingExclusion = (selectedTitles, setOpenConfirm) => [
  {
    key: 'setToPendingExclusion',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <FlagOutlined className='icons-flag' /> Send to Pending Exclusion List
      </MenuItem>
    ),
    onSelect: () =>
      selectedTitles?.length && setOpenConfirm({ type: PENDING_EXCLUSION })
  }
];

export const toExclusion = (selectedTitles, setOpenConfirm) => [
  {
    key: 'setToExclusion',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <FlagOutlined className='icons-flag' /> Send title(s) to Exclusion List
      </MenuItem>
    ),
    onSelect: () =>
      selectedTitles?.length && setOpenConfirm({ type: ALL_EXCLUDED })
  }
];

export const toPodiumTitles = (selectedTitles, setOpenConfirm) => [
  {
    key: 'setToPodiumTitle',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <SolutionOutlined className='icons-podium-title' /> Send title(s) to Podium Titles
      </MenuItem>
    ),
    onSelect: () =>
      selectedTitles?.length && setOpenConfirm({ type: PODIUM_TITLES })
  }
];

export const toWatchlist = (selectedTitles, setOpenConfirm) => [
  {
    key: 'setToWatchlist',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <EyeOutlined className='icons-eye' /> Send title(s) to Watchlist
      </MenuItem>
    ),
    onSelect: () =>
      selectedTitles?.length && setOpenConfirm({ type: WATCHLIST })
  }
];

export const returnAll = (selectedTitles, setOpenConfirm) => [
  {
    key: 'returnAll',
    text: (
      <MenuItem hide={!selectedTitles?.length}>
        <RollbackOutlined className='icons-rollback' /> Return all title(s)
      </MenuItem>
    ),
    onSelect: () => selectedTitles?.length && setOpenConfirm({ type: RETURN })
  }
];

export const tabOptionsMapping = ({
  selectedTitles,
  setOpenConfirm,
  setOpenDeleteModal,
  setOpenBlockAuthorsModal
}: {
  selectedTitles: [];
  setOpenConfirm: Dispatch<SetStateAction<{ type: string }>>;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  setOpenBlockAuthorsModal: Dispatch<SetStateAction<boolean>>;
}) => ({
  [ALL_NEW_TITLE]: [
    ...toPendingExclusion(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [HOT_TITLES]: [
    ...toPendingExclusion(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [WATCHLIST]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [PENDING_EXCLUSION]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [ALL_EXCLUDED]: [
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [MANUALLY_ADDED]: [
    ...toPendingExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [COMPETITORS]: [
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [PODIUM_TITLES]: [
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [SEND_TO_CRM]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [ALL_TITLES_RR]: [
    ...toPendingExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [WATCHLIST_RR]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [POPULAR]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [TRENDING]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [RISING_STARS]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [PENDING_EXCLUSION_RR]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [ALL_EXCLUDED_RR]: [
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [PODIUM_TITLES_RR]: [
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ],
  [SENT_TO_CRM_RR]: [
    ...toExclusion(selectedTitles, setOpenConfirm),
    ...toWatchlist(selectedTitles, setOpenConfirm),
    ...toPodiumTitles(selectedTitles, setOpenConfirm),
    ...returnAll(selectedTitles, setOpenConfirm),
    ...commonOptions(selectedTitles, setOpenDeleteModal, setOpenBlockAuthorsModal)
  ]
});
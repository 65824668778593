import React, { useMemo } from 'react';
import { FilterProvider } from '../../context/Filter.context';
import BooksSection from './Books.section';
import { SortProvider } from '../../context/Sort.context';
import '../Common/styles.css';
import { useUserPermit } from '../../context/User.context';
import { ActionsProvider } from '../../context/Actions.context';
import { GenreControllerProvider } from '../../context/GenreController.context';
import getFilter from '../Common/utils/getFilter';
import { BookstatEndpoints } from '../../core/constants';
import { ISource } from '../Common/types/types';

const getTabId = (endpoint: string): string => {
  const tabId = Object.keys(BookstatEndpoints).find(
    (key) => BookstatEndpoints[key] === `/${endpoint}`
  ) as string;

  return tabId;
};

const BooksSectionWrapper: React.FC<ISource> = React.memo((source) => {
  const { role, default_filters: userDefaultFilters } = useUserPermit();
  const search = useMemo(
    () => getFilter({ getTabId, userRole: role, userDefaultFilters }),
    [getTabId, role, userDefaultFilters]
  );

  return (
    <FilterProvider filter={search.filter}>
      <SortProvider sort={search.sort}>
        <ActionsProvider source={source}>
          <GenreControllerProvider>
            <BooksSection />
          </GenreControllerProvider>
        </ActionsProvider>
      </SortProvider>
    </FilterProvider>
  );
});

export default BooksSectionWrapper;